import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import theme from 'theme';

import { Dashboard } from 'components/Dashboard';

import { QUEUE_MODULES } from 'configs/routes';
import { getSiteStatistics } from 'helpers';
import { actions as authActions } from 'redux/auth';
import { actions as queueActions } from 'redux/queue';

import { ATTENTION_MODULE, statisticsLabels } from './helpers';
import * as S from './styled';

const QueueDashboard = () => {
  const attentionTasks = useSelector(state => state.queue.attentionTasks);
  const selectedSite = useSelector(state => state.auth.selectedSite);
  const currentSiteStatistics = useSelector(state => state.queue.currentSiteStatistics);
  const siteStatistics = getSiteStatistics(currentSiteStatistics, statisticsLabels);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(authActions.fetchUserSitesRequest());
    dispatch(queueActions.fetchSiteStatisticsRequest(selectedSite));
    dispatch(queueActions.fetchAttentionTasksRequest(selectedSite));

    const params = new URLSearchParams(location.search);
    const siteId = params.get('siteId');
    if (siteId) {
      dispatch(authActions.selectedSiteChange({ siteId }));
    }
  }, []);

  useEffect(() => {
    dispatch(queueActions.fetchSiteStatisticsRequest(selectedSite));
    dispatch(queueActions.fetchAttentionTasksRequest(selectedSite));
  }, [dispatch, selectedSite]);

  const checkCircleColor = (label, value) => {
    if (label === 'attention') {
      return theme.primaryRed;
    } else if (label === 'past due' && value > 0) {
      return theme.tertiaryOrange;
    }
    return theme.primaryQueue;
  };

  return (
    <Dashboard
      attentionTasks={attentionTasks}
      modules={QUEUE_MODULES}
      topContent={
        <S.StatisticsContainer>
          <S.StaticticsHeader>What is happening today ?</S.StaticticsHeader>
          <S.StatisticsModules>
            {!!siteStatistics.length &&
              siteStatistics.map(({ value, label }) => (
                <S.StatisticsModuleContainer key={label}>
                  <S.StatisticsValue color={checkCircleColor(label, value)}>{value}</S.StatisticsValue>
                  <S.StatisticsLabel>{label}</S.StatisticsLabel>
                </S.StatisticsModuleContainer>
              ))}
          </S.StatisticsModules>
        </S.StatisticsContainer>
      }
      attentionModuleBadge={<S.TasksNumberContainer>{attentionTasks}</S.TasksNumberContainer>}
      attentionModule={ATTENTION_MODULE}
    />
  );
};

export default QueueDashboard;
