/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useModal } from '@ebay/nice-modal-react';

import { progressTasksToggle, attentionTasksToggle } from 'configs/toggles';

import { actions as authActions } from 'redux/auth';
import { getSearchParam } from 'helpers';

import { actions as queueActions } from 'redux/queue';
import { openErrorDialog } from 'redux/errorHandler';

import { ContentContainer } from 'components/Layout/Containers';
import { BackIcon } from 'components/Layout/Icons';
import Period from 'components/Period';
import Subheader from 'components/Subheader';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';

import { ArchivingDialog } from './ArchivingDialog';
import { SpreadsheetDownloadDialog } from './SpreadsheetDownloadDialog';
import Footer from './Footer';
import List from './List';
import Toolbar from './Toolbar';
import { SummaryInfo } from './SummaryInfo';
import theme from 'theme';

const Overview = () => {
  const [alertDialogOpened, setAlertDialogOpened] = useState(false);
  const error = useSelector(state => state.queue.error);
  const lists = useSelector(state => state.queue.queueList);
  const loading = useSelector(state => state.queue.loading);
  const commitMessage = useSelector(state => state.queue.commitMessage);
  const sitesAvailableToUserFullInfo = useSelector(state => state.auth.user.sitesAvailableToUserFullInfo);
  const { date, period } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const archivingModal = useModal(ArchivingDialog);
  const itemDetailModal = useModal(SummaryInfo);
  const spreadsheetDownloadDialog = useModal(SpreadsheetDownloadDialog);
  const [selectedDate, setSelectedDate] = useState(date);

  const reducer = (accumulator, currentValue) => accumulator + currentValue;

  const isAttentionTasks = period === 'all' || period === 'parts';
  const rangeStart = moment(date);

  const unitNames = lists[period].keys.sort();
  const units = lists[period].entities;
  const countTasksArray = lists.month.ids;

  progressTasksToggle[2].total = countTasksArray.reduce(reducer, 0);

  const onCommitClick = unitId => {
    archivingModal.show({ unitId, date, period });
  };

  const onExportClick = unit => {
    spreadsheetDownloadDialog.show({
      selectedUnit: unit,
      date,
      period,
    });
  };

  const onNext = () => {
    const rangeStart = moment(date);

    let newDate;

    if (period === 'today') {
      newDate = rangeStart.add(1, 'd').format('YYYY-MM-DD');
    }
    if (period === 'days') {
      newDate = rangeStart.add(1, 'w').format('YYYY-MM-DD');
    }
    if (period === 'month') {
      newDate = rangeStart.add(1, 'month').format('YYYY-MM-DD');
    }
    setSelectedDate(rangeStart);
    history.push(`../${newDate}/${period}`);
  };

  const onPrev = () => {
    const rangeStart = moment(date);

    let newDate;

    if (period === 'today') {
      newDate = rangeStart.subtract(1, 'd').format('YYYY-MM-DD');
    }
    if (period === 'days') {
      newDate = rangeStart.subtract(1, 'w').format('YYYY-MM-DD');
    }
    if (period === 'month') {
      newDate = rangeStart.subtract(1, 'month').format('YYYY-MM-DD');
    }
    setSelectedDate(rangeStart);
    history.push(`../${newDate}/${period}`);
  };

  const goToDetailsPage = item => {
    dispatch(queueActions.setSelectedTasksInfo(item));
    const assigneeId = item.AssigneeId || 'allusers';
    history.push(`/queue/${date}/${period}/${item.UnitId}/category/${item.AssigneeName}/${assigneeId}`);
  };

  const onUnitItemClick = item => {
    itemDetailModal.show({ item, date });
  };

  const closeAlertDialog = () => setAlertDialogOpened(false);

  const periodHandler = value => {
    const rangeStart = moment().format('YYYY-MM-DD');
    history.push(`../${rangeStart}/${value.key}`);
    setSelectedDate(date);
    if (value.key === progressTasksToggle[2].key) {
      dispatch(queueActions.fetchQueueListRequest({ rangeStart: date, period: 'month' }));
    }
  };

  const goBack = () => history.push('/queue');

  useEffect(() => {
    const siteFromParams = getSearchParam('site');

    if (siteFromParams) {
      authActions.onSiteChange({ checkedSite: siteFromParams, sitesCollection: sitesAvailableToUserFullInfo });
    }

    dispatch(queueActions.fetchQueueListRequest({ rangeStart: date, period }));
  }, [dispatch, period, sitesAvailableToUserFullInfo]);

  useEffect(() => {
    if (!isAttentionTasks && period !== 'month') {
      dispatch(queueActions.fetchQueueListRequest({ rangeStart: date, period: 'month' }));
    }
  }, [period]);

  useEffect(() => {
    if (commitMessage === 'unit contains demo tasks. Unit cannot be committed.') {
      setAlertDialogOpened(true);
    }
  }, []);

  useEffect(() => {
    if (error) {
      dispatch(openErrorDialog(error, 'Error'));
      dispatch(queueActions.stateErrorClear());
    }
  }, []);

  return [
    <ContentContainer key="Queue Overview">
      <Subheader
        title={isAttentionTasks ? 'Attention' : 'Progress'}
        leftButtons={[
          {
            icon: <BackIcon />,
            handler: goBack,
            hint: 'Back',
          },
        ]}
        isSiteOnLeftSide
      />
      <Toolbar
        periodConfig={isAttentionTasks ? attentionTasksToggle : progressTasksToggle}
        periodHandler={periodHandler}
        selectedPeriod={period}
        minWidth
      />
      {!isAttentionTasks && (
        <Period
          type={period}
          configs={progressTasksToggle}
          startRange={rangeStart}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      )}
      {!loading && (
        <List
          onCommitUnit={onCommitClick}
          onExportUnit={onExportClick}
          onUnitItemClick={onUnitItemClick}
          goToDetailsPage={goToDetailsPage}
          units={units}
          unitsNames={unitNames}
          isAttentionTasks={isAttentionTasks}
        />
      )}
    </ContentContainer>,
    !isAttentionTasks && <Footer key="Queue Footer" onPrev={onPrev} onNext={onNext} period={period} />,
    <ConfirmationDialog
      isNext={false}
      handleClose={closeAlertDialog}
      title={'Alert'}
      titleColor={theme.redButton}
      text={'Unit contains demo tasks. Unit cannot be committed.'}
      onClick={closeAlertDialog}
      label={'Close'}
      open={alertDialogOpened}
    />,
  ];
};

export default Overview;
