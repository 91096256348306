import React, { FC } from 'react';
import { PrimaryIconButton, SecondaryLinkButton, ListItem } from 'components/Layout/Section';
import { ImageIcon, IndustryIcon, LimitsIcon, ListsModuleIcon } from 'components/Layout/Icons';

type PropsType = {
  openLogoDialog: () => void;
  openLimitsDialog: () => void;
};

export const General: FC<PropsType> = ({ openLogoDialog, openLimitsDialog }) => (
  <ListItem
    key="General"
    name="General"
    zIndex={8}
    content={
      <>
        <PrimaryIconButton onClick={openLogoDialog} icon={<ImageIcon />} label="Logos" />
        <PrimaryIconButton onClick={openLimitsDialog} icon={<LimitsIcon />} label="Limits" />
        <SecondaryLinkButton path="/globalsettings/industries" icon={<IndustryIcon />} label="Industries" />
        <SecondaryLinkButton path="/globalsettings/asset" icon={<ListsModuleIcon />} label="Templates" disabled />
      </>
    }
  />
);
